import { PageTypes } from './Pages';
import { UrlService } from '../services/UrlService';

export function getHeaderMenuLinks(t, currentLang) {
    return [
        {
            title: t('HOME_PAGE'),
            link: '/',
            categoryPageName: 'Home',
        },
        {
            title: t('ALL_CAT'),
            link: UrlService.createURL(`/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/`),
            categoryPageName: 'All',
        },
        {
            title: t('BEST_CAT'),
            link: UrlService.createURL(
              `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/${t('BEST_CAT').toLowerCase()}/`
            ),
            categoryPageName: 'Best',
        },
        {
            title: t('NEW_CAT'),
            link: UrlService.createURL(
              `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/${t('NEW_CAT').toLowerCase()}/`
            ),
            categoryPageName: 'New',
        },
        {
            title: t('ALL_CATEGORIES_PAGE'),
            link: UrlService.createURL(`/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.AllCategories)}/`),
            categoryPageName: 'AllCategories',
        },
    ];
}
